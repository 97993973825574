import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Home from './routes/Home';
import Contact from './routes/Contact';
import About from './routes/About';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

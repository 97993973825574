
export default function Projects() {

  const deployed = [
    {
      title: 'Mentor A Promise',
      desc: 'A Website and a web application for a charity organization that includes a Volunteer Coordination and Communication app. This app steamlines the management of schedules, book shifts and facilitates seamless communication between volunteers and coordinators.',
      url: 'https://www.mentorapromise.org',
      // git: 'https://github.com/Regie4233/mentorapromise-next',
      slug: 'mentorapromise',
      img: './map.png',
      size: 550,
      stack: ['Nextjs', 'Redux Toolkit', 'Nodejs', 'Python', 'Websocket', 'Micro-Services Architecture']
    },
    {
      title: 'Jam Coast Shipping',
      desc: 'A CMS (Content Management System) Powered Website with Integrated Email Marketing Features for a cargo shipping company.',
      url: 'https://www.jamcoastship.com/',
      slug: 'jamcoast',
      img: './jmcst.png',
      size: 550,
      stack: ['Nextjs', 'Nodejs', 'Strapi CMS', 'Brevo']
    },
    {
      title: 'AWS Cloud Practitioner Practice Exam',
      desc: 'A free practice exam app for AWS Cloud Practitioner Certitication.',
      url: 'https://awspractice.simon.us.com',
      git: 'https://github.com/Regie4233/aws-cloud-practitioner-practice-test-app',
      slug: 'awspracticeexam',
      img: './awspract.png',
      size: 550,
      stack: ['Nextjs', 'Typescript', 'Nodejs', 'Redux Toolkit']
    },
    {
      title: 'AWS Questions Web Scraper',
      desc: 'A python web scrapper for scraping AWS Cloud Practitioner questions on Github',
      slug: 'awsscrapper',
      git: 'https://github.com/Regie4233/Aws-Question-Scraper',
      img: './Python-PNG.png',
      size: 250,
      stack: ['Python']
    }
  ]

  const mockups = [
    {
      title: 'AWS Questions Web Scraper',
      desc: 'A python web scrapper for scraping AWS Cloud Practitioner questions on Github',
      url: null,
      img: ''
    }
  ]

  return (
    <main>
      <h2 className="text-center text-xl font-semibold">- My Work -</h2>
      <ul className="flex flex-col p-12 gap-12" id="proj">
        {
          deployed.map((item, ii) => (
            <li className="col-span-2 w-full flex flex-col p-2 gap-2" key={ii}>
              <h3 className="text-2xl font-semibold text-center bg-slate-100 p-2">
                {item.title}
              </h3>
              <section className="flex flex-row gap-2">
                <img alt="thumb" src={item.img} className="" width={item.size} />
                <div className="flex flex-col justify-evenly p-8">
                  <p>{item.desc}</p>
                  <ul className="grid grid-cols-6">
                    <li className="col-span-full font-medium  ">Tech Stack</li>
                    {
                      item.stack.map((e, i) => (
                        <li key={i} className={`${e.length > 14 && ('col-span-6')} ${item.stack.length < 6 && ('self-center')}`}>
                          <p >{e}</p>
                        </li>
                      ))
                    }
                  </ul>
                </div>

              </section>

              <div className="flex flex-col">
                <a href={item.git}
                  style={item.git === undefined ? { display: "none" } : { contentVisibility: "visible" }}>
                  <span>Github:</span><span className="underline px-2">{item.git}</span>
                </a>
                <a href={item.url} target="noref"
                  style={item.url === undefined ? { display: "none" } : { contentVisibility: "visible" }}>
                  <span>Site:</span><span className="underline px-2">{item.url}
                  </span>
                </a>
              </div>

            </li>
          ))
        }
      </ul>
    </main>
  )
}

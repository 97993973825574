import React, { useState } from 'react'
import ContactModal from './ContactModal';

export default function NaviHeader() {
    const [open, setOpen] = useState(false);
    return (
        <header className='bg-gray-800 p-4 flex flex-row justify-between'>
            <h1 className='text-white text-2xl font-bold'><a href='/'>Reginald Simon</a></h1>
            <nav className='text-white pr-20'>
                <ul className='flex flex-row gap-8 text-xl'>
                    <li>
                        <a href='/#proj'>
                            projects
                        </a>
                    </li>
                    {/* <li>
                        <a href='/about'>about</a>
                    </li> */}
                    <li>
                        <a href='/contact'>contact</a>
                        {/* <div onClick={() => setOpen(!open)} href='/contact'>contact</div> */}
                        {/* {
                            open && (
                                <ContactModal />
                            )
                        }
                         */}
                    </li>
                </ul>
            </nav>
        </header>
    )
}
import { RiNextjsFill, RiReactjsFill, RiTailwindCssFill, RiNodejsLine } from "react-icons/ri";
import {BsBootstrapFill} from "react-icons/bs";
import {FaFigma, FaDocker,FaPython, FaAws, FaLinux} from "react-icons/fa";
import {SiPortainer, SiNginx} from "react-icons/si";
export default function Skills() {
    const techstacks = [
        {
            name: 'Front End',
            skills: [
                {
                    name: 'ReactJs',
                    icon: <RiReactjsFill size={50}/>
                },
                {
                    name: 'NextJs',
                    icon: <RiNextjsFill size={50}/>
                },
                {
                    name: 'Tailwind CSS',
                    icon: <RiTailwindCssFill size={50}/>
                },
                {
                    name: 'Bootstrap',
                    icon: <BsBootstrapFill size={50}/>
                },
                {
                    name: 'Figma',
                    icon: <FaFigma size={50}/>
                }
            ]
        },
        {
            name: 'Back End',
            skills: [
                {
                    name: 'NodeJs',
                    icon: <RiNodejsLine size={50}/>
                },
                {
                    name: 'ExpressJs',
                    icon: <RiNodejsLine size={50}/>
                },
                {
                    name: 'Python',
                    icon: <FaPython size={50}/>
                }
            ]
        },
        {
            name: 'Infrastructure as a Service (IaaS)',
            skills: [
                {
                    name: 'AWS EC2',
                    icon: <FaAws size={50}/>
                },
                {
                    name: 'AWS Lightsail',
                    icon: <FaAws size={50}/>
                },
                {
                    name: 'Cloud Infrastructure',
                    icon: <FaLinux size={50}/>
                },
            ]
        },
        {
            name: 'DevOps Tool',
            skills: [
                {
                    name: 'Docker',
                    icon: <FaDocker size={50}/>
                },
                {
                    name: 'NGINX',
                    icon: <SiNginx size={50}/>
                },
                {
                    name: 'Portainer',
                    icon: <SiPortainer size={50}/>
                },
            ]
        }
    ]
    return (
        <main>
            {/* <h1 className="text-center p-8 text-3xl font-bold">Tech Stack</h1> */}
            <div className="grid grid-cols-4 justify-items-center p-12 gap-12">
                {
                    techstacks.map((stacks) => (
                        <section className='col-span-2 p-4 border-2 border-dashed w-full'>
                            <h2 className="text-center p-8 text-xl font-semibold">{stacks.name}</h2>
                            <ul className="flex flex-row gap-12 justify-center">
                                {
                                    stacks.skills.map((skill) => {
                                        // const rem = techstacks.length % 3;
                                        return (
                                            <li className="flex flex-col items-center">
                                                {skill.icon}
                                                {/* <img alt="skill icon" src={skill.src} width={50} height={50} /> */}
                                                <p>{skill.name}</p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </section>
                    ))
                }
            </div>


        </main>
    )
}

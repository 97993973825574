import React from 'react'
import Hero from '../components/Hero'
import Skills from '../components/Skills'
import Projects from '../components/Projects'

export default function Home() {
  return (
    <main>
    <Hero />
    <Skills />
    <Projects />
  </main>
  )
}

import React from 'react'

export default function Contact() {
  return (
    <main className='bg-gray-900'>
 <section className='flex flex-col gap-12 items-center mx-auto py-8 w-1/2 h-screen bg-white'>
      <h2 className='text-2xl'>Thanks for checking out my portfolio!</h2>
      <div className='flex flex-col items-center text-xl border-2 border-dashed p-12'>
      <a href='tel:+12013287446' className='underline'>201-328-7446</a>
      <a href='mailto:reggie@simon.us.com' className='underline'>reggie@simon.us.com</a>
      </div>
   </section>
    </main>
  
  )
}

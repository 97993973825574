import React from 'react'

export default function Hero() {
    return (
        <main>
            <section className=" bg-gray-900 text-gray-500 p-8 w-full">
                <h1 className="text-[15rem] font-semibold font-main w-fit">
                    Full Stack —
                </h1>
                <div className="flex flex-row items-center gap-12">
                    <h1 className="text-[15rem] font-semibold font-main">
                        Developer
                    </h1>
                    <p className="text-white text-xl w-1/2">I am <span className="font-bold text-blue-500">Reginald Simon</span>, a passionate web developer specializing in creating <span className="font-bold text-blue-500">responsive</span>,
                        <span className="font-bold text-blue-500"> high-performance applications</span>.
                        <br />Experienced in development, deployment and UI design principles. <br /> A Natural problem solver, translating user requirements into a working user platform.
                        </p>
                </div>
            </section>
        </main>
    )
}
